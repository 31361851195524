<template>
  <div class="links">
    <banner :title="title" class="banner"></banner>
    <all-links class="all-links"></all-links>
  </div>
</template>

<script>
import Banner from "../components/Common/Banner.vue";
import AllLinks from "../components/Links/AllLinks.vue";

export default {
  components: { Banner, AllLinks },
  data() {
    return {
      title: "Links",
    };
  },
};
</script>

<style scoped>
.all-links {
  padding: 2rem 4rem;
}

@media only screen and (max-width: 600px) {
  .all-links {
    padding: 2rem;
  }

  .banner {
    margin-top: 0;
  }
}
</style>