<template>
  <landing></landing>
</template>

<script>
import Landing from "../components/Landing.vue";
export default {
  name: "Home",
  components: { Landing },
};
</script>

<style scoped>
</style>
