var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-text-field',{attrs:{"outlined":"","placeholder":"Search","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.employees,"items-per-page":10,"search":_vm.search,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.photoURL",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"primary","size":"36"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(item.name.charAt(0)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.view(item.uid)}}},[_c('v-list-item-title',[_vm._v("View")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),(!item.isAdmin)?_c('v-list-item',{on:{"click":function($event){return _vm.makeAsAdmin(item)}}},[_c('v-list-item-title',[_vm._v("Make as admin")])],1):_vm._e(),(item.isAdmin && item.uid !== _vm.currentUser.uid)?_c('v-list-item',{on:{"click":function($event){return _vm.removeFromAdmin(item)}}},[_c('v-list-item-title',[_vm._v("Remove from admin")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm._delete(item.uid)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }