<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <stats></stats>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Stats from "../components/dashboard/Stats.vue";
export default {
  components: { Stats },
  name: "Dashboard",
};
</script>

<style scoped>
</style>