<template>
  <div class="issue-card mx-5 pa-5">
    <div class="issue-card-header d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <h3>{{ item.type }}</h3>
        <v-chip class="ma-2" :color="getColor()" small outlined>
          {{ item.status }}
        </v-chip>
      </div>
      <v-btn color="red" icon @click="deleteIssue()">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <p>
      {{ item.description }}
    </p>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:actions> View Remarks </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text>
            <h3 class="my-2">Remarks:</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Emp ID</th>
                    <th class="text-left">Remark</th>
                    <th class="text-left">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(remark, index) in item.remarks" :key="index">
                    <td>{{ remark.remarkBy }}</td>
                    <td>{{ remark.empId }}</td>
                    <td>{{ remark.remark }}</td>
                    <td>{{ remark.createdAt.toDate().toLocaleString() }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { issueRef } from "../../config/firebase";
export default {
  props: ["item"],
  methods: {
    getColor() {
      switch (this.item.status) {
        case "Pending":
          return "warning";
        case "In Progress":
          return "info";
        case "Resolved":
          return "success";
        default:
          return "primary";
      }
    },
    async deleteIssue() {
      try {
        await issueRef.doc(this.item.id).delete();
        this.$toast.open({
          message: "Issue deleted",
          type: "success",
          position: "top",
        });
        this.$emit("refresh");
      } catch (error) {
        this.$toast.open({
          message: error.message,
          type: "error",
          position: "top",
        });
      }
    },
  },
};
</script>

<style scoped>
.issue-card {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

p {
  color: #796d6d;
}

>>> .v-card {
  box-shadow: none !important;
}
</style>