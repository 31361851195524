<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="secondary"
          outlined
          class="d-block ml-auto"
          @click="addNewLinkDialog = true"
        >
          <v-icon class="ml-2">mdi-upload</v-icon>
          Upload New
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4"> </v-col>
      <v-col cols="12" md="4"> </v-col>
      <v-col cols="12" md="4">
        <v-select
          outlined
          dense
          label="Filter by"
          :items="types"
          class="ml-auto"
          @change="handleFilter"
          v-model="type"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <ul v-if="links.length > 0">
        <li
          v-for="(link, index) in links"
          :key="index"
          class="d-flex align-center mb-3"
        >
          <a :href="link.link" target="_blank">
            {{ link.title ? link.title : cropLink(link.link) }}
          </a>
          <div class="actions ml-3">
            <v-btn icon color="secondary" @click="edit(link)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="_delete(link.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </li>
      </ul>
      <div v-else class="d-block mx-auto mt-5">
        <img
          src="../../assets/images/no-data.png"
          alt="No Data"
          class="d-block mx-auto"
        />
        <h3 class="text-center">No data found</h3>
      </div>
    </v-row>
    <add-new-file-link
      :visible="addNewLinkDialog"
      :link="link"
      :isEdit="isEdit"
      @close="handleClose"
    ></add-new-file-link>
  </section>
</template>

<script>
import { fileLinksRef } from "../../config/firebase";
import AddNewFileLink from "../modals/AddNewFileLink.vue";
export default {
  components: {
    AddNewFileLink,
  },
  data() {
    return {
      links: [],
      addNewLinkDialog: false,
      link: {
        link: "",
        title: "",
        category: "",
      },
      isEdit: false,
      types: [
        "ALL",
        "MRPL CIRCULARS",
        "LETTER TO MGMT",
        "LETTER TO MEU",
        "MINUTES OF EC",
        "STRUCTURED MEETINGS",
        "REPORTS",
      ],
      type: "ALL",
      tempLinks: [],
    };
  },
  created() {
    this.getLinks();
  },
  methods: {
    cropLink(link) {
      return link.length > 50 ? link.substring(0, 50) + "..." : link;
    },
    handleClose() {
      this.addNewLinkDialog = false;
      this.isEdit = false;
      this.link = {
        link: "",
        category: "",
      };
    },
    handleFilter() {
      if (this.type === "ALL") {
        this.links = this.tempLinks;
      } else {
        this.links = this.tempLinks.filter((link) => {
          return link.category === this.type;
        });
      }
    },
    processSnapshot(snapshot) {
      return new Promise((resolve) => {
        let count = 0;
        snapshot.forEach((doc) => {
          this.links.push({ ...doc.data(), id: doc.id });
          count++;
          // Check if it's the last item in the array
          if (count === snapshot.size) {
            resolve(this.links); // Resolve the promise when the last item is processed
          }
        });
      });
    },
    async getLinks() {
      const fileLinksSnapshot = await fileLinksRef
        .orderBy("createdAt", "desc")
        .get();

      const documents = await this.processSnapshot(fileLinksSnapshot);

      documents.sort((a, b) => {
        const numA = parseInt(a.title.match(/\d+/)[0], 10);
        const numB = parseInt(b.title.match(/\d+/)[0], 10);

        return numB - numA;
      });
      this.links = documents;
      this.tempLinks = documents;
    },
    edit(link) {
      this.link = link;
      this.isEdit = true;
      this.addNewLinkDialog = true;
    },
    async _delete(id) {
      await fileLinksRef.doc(id).delete();
      this.$toast.open({
        message: "Link deleted successfully",
        type: "success",
        position: "top",
      });
    },
  },
};
</script>

<style scoped>
.actions {
  display: none;
}

li:hover .actions {
  display: block;
}
</style>