<template>
  <v-container>
    <v-text-field
      outlined
      placeholder="Search"
      dense
      v-model="search"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="employees"
      :items-per-page="10"
      class="elevation-1"
      :search="search"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.photoURL`]="{ item }">
        <v-avatar color="primary" size="36">
          <span class="white--text text-h6">{{ item.name.charAt(0) }}</span>
        </v-avatar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" icon dark v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="view(item.uid)">
              <v-list-item-title>View</v-list-item-title>
            </v-list-item>
            <v-list-item @click="edit(item)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="makeAsAdmin(item)" v-if="!item.isAdmin">
              <v-list-item-title>Make as admin</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="removeFromAdmin(item)"
              v-if="item.isAdmin && item.uid !== currentUser.uid"
            >
              <v-list-item-title>Remove from admin</v-list-item-title>
            </v-list-item>
            <v-list-item @click="_delete(item.uid)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { employeeRef } from "../../config/firebase";
import { mapState } from "vuex";

export default {
  components: {},
  name: "EmployeesList",
  data() {
    return {
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "photoURL",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Employee ID", value: "empId" },
        { text: "Age", value: "age" },
        { text: "Grade", value: "grade" },
        { text: "Email", value: "email" },
        { text: "Mobile Number", value: "phoneNumber" },
        { text: "Actions", value: "actions" },
      ],
      employees: [],
      search: "",
      editDialog: false,
      editData: {},
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      const exployeesSnapshot = employeeRef.orderBy("createdAt", "desc").get();
      exployeesSnapshot.then((snapshot) => {
        snapshot.forEach((doc) => {
          this.employees.push({ ...doc.data(), uid: doc.id });
        });
      });
    },
    async makeAsAdmin(user) {
      try {
        await employeeRef.doc(user.uid).set({ isAdmin: true }, { merge: true });
        this.$toast.success(`Marked ${user.name} as admin`, {
          position: "top",
        });
      } catch (error) {
        this.$toast.error(error.message, {
          position: "top",
        });
      }
    },
    async removeFromAdmin(user) {
      try {
        await employeeRef
          .doc(user.uid)
          .set({ isAdmin: false }, { merge: true });
        this.$toast.success(`Removed ${user.name} from admin`, {
          position: "top",
        });
      } catch (error) {
        this.$toast.error(error.message, {
          position: "top",
        });
      }
    },
    edit(user) {
      this.editDialog = true;
      this.editData = user;
    },
    view(uid) {
      this.$router.push(`/employees/${uid}`);
    },
    async _delete(uid) {
      try {
        await employeeRef.doc(uid).delete();
        this.$toast.success(`Deleted`, {
          position: "top",
        });
        this.getData();
      } catch (error) {
        this.$toast.error(error.message, {
          position: "top",
        });
      }
    },
  },
};
</script>

<style scoped>
>>> th {
  color: var(--primary-color) !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
</style>