import { employeeRef } from "../config/firebase";

class Employee {
  constructor() {}

  async create(data) {
    try {
      const response = await employeeRef.doc(data.uid).set(data);
      return response;
    } catch (error) {
      return error;
    }
  }
  update(id, data) {
    try {
      const document = employeeRef.doc(id).set(data, { merge: true });
      return document;
    } catch (error) {
      return error;
    }
  }
  delete(id) {
    console.log(id);
  }
  async get(id) {
    try {
      const document = await employeeRef.doc(id).get();
      return document.data();
    } catch (error) {
      return error;
    }
  }
  async getAll() {
    let employees = [];
    try {
      const documents = await employeeRef.get();
      documents.forEach((document) => {
        employees.push({
          ...document.data(),
        });
      });
      return employees;
    } catch (error) {
      console.log(error);
    }
  }
}

export const employee = new Employee();
