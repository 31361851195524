<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="secondary"
          outlined
          class="d-block ml-auto"
          @click="addNewReportDialog = true"
        >
          <v-icon class="ml-2">mdi-upload</v-icon>
          Upload New
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="items.length > 0">
      <v-col
        cols="12"
        md="4"
        v-for="(item, index) in items"
        :key="index"
        class="mb-5"
      >
        <report-card :item="item"></report-card>
      </v-col>
    </v-row>
    <div v-else class="text-center">
      <img src="../../assets/images/no-data.png" />
      <div>No data found</div>
    </div>
    <add-new-report
      :visible="addNewReportDialog"
      @close="addNewReportDialog = false"
    ></add-new-report>
  </div>
</template>

<script>
import { reportsRef } from "../../config/firebase";
import AddNewReport from "../modals/AddNewReport.vue";
import ReportCard from "./ReportCard.vue";
export default {
  components: { ReportCard, AddNewReport },
  data() {
    return {
      items: [],
      addNewReportDialog: false,
    };
  },
  created() {
    this.getReports();
  },
  methods: {
    getReports() {
      reportsRef
        .orderBy("createdAt", "desc")
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            return;
          }
          snapshot.forEach((doc) => {
            this.items.push({ id: doc.id, ...doc.data() });
          });
        });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .col-md-4 {
    width: 100%;
    margin-bottom: 1rem !important;
  }
}
</style>