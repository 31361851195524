<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title class="heading"
        >Issue Remark
        <v-spacer></v-spacer>
        <v-btn icon color="error" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-textarea
            outlined
            placeholder="Remark"
            dense
            v-model="remark.remark"
          ></v-textarea>
          <v-btn color="secondary" @click="submit()" class="d-block mx-auto"
            >Submit
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { fieldValueRef, issueRef } from "../../config/firebase";

export default {
  name: "IssueRemark",
  props: ["visible", "docId"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          return this.$emit("close");
        }
      },
    },
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
  data() {
    return {
      rules: {
        description: [(v) => !!v || "Remark is required"],
      },
      remark: {
        uid: "",
        remarkBy: "",
        empId: "",
        remark: "",
        createdAt: "",
      },
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.remark.uid = this.currentUser.uid;
          this.remark.remarkBy = this.currentUser.name;
          this.remark.empId = this.currentUser.empId;
          this.remark.createdAt = new Date();
          await issueRef.doc(this.docId).update({
            remarks: fieldValueRef.arrayUnion(this.remark),
          });
          this.$toast.success("Remark Submitted!", {
            position: "top",
          });
          this.show = false;
          this.remark = {
            uid: "",
            remarkBy: "",
            empId: "",
            remark: "",
            createdAt: "",
          };
        } catch (error) {
          this.$toast.error(error.message, {
            position: "top",
          });
        }
      }
    },
  },
};
</script>

<style>
</style>