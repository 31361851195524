<template>
  <v-container>
    <div v-if="type === 'isBearer'">
      <div
        class="d-flex align-center bearer-list"
        v-if="administration.bearers.length > 0"
      >
        <bearer-card
          v-for="(bearer, index) in administration.bearers"
          :key="index"
          :bearer="bearer"
          class="mb-10"
          @edit="handleEdit"
          @delete="handleDelete"
        ></bearer-card>
      </div>
      <div v-else class="text-center">
        <img src="../../assets/images/no-data.png" />
        <div>No data found</div>
      </div>
    </div>
    <div v-if="type === 'isExecutive'">
      <div
        class="d-flex align-center bearer-list"
        v-if="administration.executives.length > 0"
      >
        <bearer-card
          v-for="(bearer, index) in administration.executives"
          :key="index"
          :bearer="bearer"
          class="mb-10"
          @edit="handleEdit"
          @delete="handleDelete"
        ></bearer-card>
      </div>
      <div v-else class="text-center">
        <img src="../../assets/images/no-data.png" />
        <div>No data found</div>
      </div>
    </div>
    <div v-if="type === 'isRepresentation'">
      <div
        class="d-flex align-center bearer-list"
        v-if="administration.representations.length > 0"
      >
        <bearer-card
          v-for="(bearer, index) in administration.representations"
          :key="index"
          :bearer="bearer"
          class="mb-10"
          @edit="handleEdit"
          @delete="handleDelete"
        ></bearer-card>
      </div>
      <div v-else class="text-center">
        <img src="../../assets/images/no-data.png" />
        <div>No data found</div>
      </div>
    </div>
  </v-container>
</template>

<script>
import BearerCard from "./BearerCard.vue";
export default {
  props: ["type", "administration"],
  components: { BearerCard },
  name: "BearersList",
  data() {
    return {};
  },
  methods: {
    handleEdit(uid) {
      this.$emit("edit", { uid, type: this.type });
    },
    handleDelete(uid) {
      this.$emit("delete", { uid, type: this.type });
    },
  },
};
</script>

<style scoped>
.bearer-list {
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  div {
    flex-wrap: wrap;
  }

  div > * {
    flex: 100%;
  }
}
</style>