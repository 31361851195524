import { storageRef } from "../config/firebase";

export default {
  methods: {
    uploadFile(path, file) {
      return new Promise((resolve) => {
        const firebaseStorageRef = storageRef
          .child(`${path}/${+new Date()}${file.name}`)
          .put(file);

        firebaseStorageRef.on(
          "status_changed",
          (snapshot) => {
            console.log(snapshot.bytesTransferred);
          },
          (error) => {
            resolve(error);
          },
          async () => {
            const downloadURL = await firebaseStorageRef.snapshot.ref.getDownloadURL();
            resolve(downloadURL);
          }
        );
      });
    },
  },
};
