<template>
  <div class="stats-row">
    <stats-card
      v-for="(stat, index) in stats"
      :key="index"
      :stat="stat"
    ></stats-card>
  </div>
</template>

<script>
import StatsCard from "./StatsCard.vue";
import dashboardStats from "../../mixins/dashboardStats";

export default {
  components: { StatsCard },
  name: "Stats",
  mixins: [dashboardStats],
};
</script>

<style scoped>
.stats-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .stats-row {
    flex-wrap: wrap;
  }

  .stats-row > * {
    flex: 100%;
    margin-bottom: 10px;
  }
}
</style>