<template>
  <v-dialog v-model="show" width="500">
    <v-form ref="form">
      <v-card>
        <v-card-title class="heading">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5">
          <v-select
            outlined
            dense
            label="Select Category"
            :items="categories"
            :rules="[(v) => !!v || 'Category is required']"
            v-model="link.category"
          ></v-select>
          <v-text-field
            v-model="link.title"
            dense
            outlined
            label="Title"
          ></v-text-field>
          <v-file-input
            v-model="link.link"
            label="Upload File"
            :rules="[(v) => !!v || 'File is required']"
            outlined
            dense
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            @click="isEdit ? update() : submit()"
            class="mx-auto"
          >
            {{ btnText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <progrss-loader
      :visible="loading"
      :loaderText="loaderText"
    ></progrss-loader>
  </v-dialog>
</template>
 
<script>
import { fileLinksRef } from "../../config/firebase";
import ProgrssLoader from "./ProgrssLoader.vue";
import uploadFileMixin from "../../mixins/uploadFile";

export default {
  components: { ProgrssLoader },
  props: ["visible", "isEdit", "link"],
  mixins: [uploadFileMixin],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) return this.$emit("close");
      },
    },
    btnText() {
      return this.isEdit ? "Update" : "Add";
    },
    title() {
      return this.isEdit ? "Edit File Link" : "Add File Link";
    },
  },
  data() {
    return {
      categories: [
        "MRPL CIRCULARS",
        "LETTER TO MGMT",
        "LETTER TO MEU",
        "MINUTES OF EC",
        "STRUCTURED MEETINGS",
        "REPORTS",
      ],
      loading: false,
      loaderText: "",
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      this.link.createdAt = new Date();
      try {
        this.loading = true;
        this.loaderText = "Uploading File...";
        this.link.link = await this.uploadFile(
          "member-corner/",
          this.link.link
        );
        await fileLinksRef.add(this.link);
        this.$toast.open({
          message: "File Link added successfully",
          type: "success",
          position: "top",
          duration: 5000,
        });
        this.loading = false;
        this.$emit("close");
      } catch (error) {
        this.loading = false;
        this.$toast.open({
          message: error.message,
          type: "error",
          position: "top",
          duration: 5000,
        });
      }
    },
    async update() {
      if (!this.$refs.form.validate()) return;
      try {
        this.loading = true;
        this.loaderText = "Uploading File...";
        if (typeof this.link.link === "object") {
          this.link.link = await this.uploadFile(
            "member-corner/",
            this.link.link
          );
        }
        await fileLinksRef.doc(this.link.id).set(this.link, { merge: true });
        this.$toast.open({
          message: "File Link updated successfully",
          type: "success",
          position: "top",
          duration: 5000,
        });
        this.loading = false;
        this.$emit("close");
      } catch (error) {
        this.loading = false;
        this.$toast.open({
          message: error.message,
          type: "error",
          position: "top",
          duration: 5000,
        });
      }
    },
  },
};
</script>

<style scoped>
</style>