<template>
  <v-container>
    <div class="d-flex justify-space-between align-center">
      <h2>Employees</h2>
      <v-btn color="primary" @click="addEmployeeDialog = true">
        <v-icon class="mr-2">mdi-plus-circle</v-icon>
        Add new
      </v-btn>
    </div>
    <employees-list class="mt-4"></employees-list>
    <add-new-employee
      :visible="addEmployeeDialog"
      @close="addEmployeeDialog = false"
    ></add-new-employee>
  </v-container>
</template>

<script>
import EmployeesList from "../components/employees/EmployeesList.vue";
import AddNewEmployee from "../components/modals/AddNewEmployee.vue";

export default {
  components: { EmployeesList, AddNewEmployee },
  name: "Employees",
  data() {
    return {
      addEmployeeDialog: false,
    };
  },
};
</script>

<style scoped>
h2 {
  color: #4f4f4f;
  font-weight: 500;
}

@media screen and (max-width: 600px) {
  div {
    flex-wrap: wrap;
  }
}
</style>