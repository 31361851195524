<template>
  <v-app>
    <layout v-if="currentUser"></layout>
    <v-main>
      <v-container
        class="px-4 py-0 fill-height"
        fluid
        v-if="$route.path !== '/'"
      >
        <v-row class="fill-height">
          <v-col>
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
      <router-view v-else></router-view>
    </v-main>
    <raise-issue
      :visible="raiseIssueDialog"
      @close="raiseIssueDialog = false"
    ></raise-issue>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Layout from "./components/Layout.vue";
import RaiseIssue from "./components/modals/RaiseIssue.vue";
import { employee } from "./service/employees";
export default {
  components: { Layout, RaiseIssue },
  name: "App",
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
  data() {
    return {
      raiseIssueDialog: false,
    };
  },
  async created() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const data = await employee.get(user.uid);
      this.$store.dispatch("SET_CURRENT_USER", data);
      this.$router.push("/dashboard");
    }
  },
  methods: {
    raiseAnIssue() {
      if (this.currentUser === null) {
        this.$toast.error("Please login to submit the issue!", {
          position: "top",
        });
      } else {
        this.raiseIssueDialog = true;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f2f2f2; */
}

:root {
  --primary-color: #10375c;
  --secondary-color: #da6267;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(2em);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 1);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 1);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 1);
}

>>> .v-btn {
  text-transform: capitalize !important;
}

@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 0;
  }
}
</style>
