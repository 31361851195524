<template>
  <v-dialog v-model="show" width="800">
    <v-card>
      <v-card-title class="heading"
        >All Remarks
        <v-spacer></v-spacer>
        <v-btn icon color="error" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <h3>Issue: {{ issue }}</h3>
        <h3 class="my-2">Remarks:</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Emp ID</th>
                <th class="text-left">Remark</th>
                <th class="text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(remark, index) in remarks" :key="index">
                <td>{{ remark.remarkBy }}</td>
                <td>{{ remark.empId }}</td>
                <td>{{ remark.remark }}</td>
                <td>{{ remark.createdAt.toDate().toLocaleString() }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ViewRemark",
  props: ["issue", "remarks", "visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          return this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped>
h3 {
  color: #4f4f4f;
  font-weight: 500;
}
</style>