<template>
  <div>
    <banner :title="title"></banner>
    <all-links class="all-links"></all-links>
  </div>
</template>

<script>
import Banner from "../components/Common/Banner.vue";
import AllLinks from "../components/FileLinks/AllLinks.vue";
export default {
  components: { Banner, AllLinks },
  data() {
    return {
      title: "Member File Links",
    };
  },
};
</script>

<style scoped>
.all-links {
  padding: 2rem 4rem;
}
</style>