<template>
  <div class="login">
    <v-row>
      <v-col cols="12" md="5">
        <a href="/">
          <img src="../assets/images/logo.svg" alt="MEU Logo" class="pa-10" />
        </a>
        <div class="form">
          <h1>Login</h1>
          <p>MRPL Employee Union</p>
          <v-form ref="form" @submit.prevent="login">
            <label>Email Address</label>
            <v-text-field
              v-model="email"
              :rules="[(v) => !!v || 'Email is required']"
              outlined
              class="mt-3"
            ></v-text-field>
            <label>Password</label>
            <v-text-field
              v-model="password"
              :rules="[(v) => !!v || 'Password is required']"
              outlined
              class="mt-3"
              :type="show ? 'text' : 'password'"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
            ></v-text-field>
            <v-btn class="mt-3" color="secondary" large depressed type="submit">
              Login
            </v-btn>
            <a
              href="#"
              class="float-right mt-3"
              @click="forgotPasswordDialog = true"
              >Forgot your password?</a
            >
          </v-form>
        </div>
      </v-col>
      <v-col cols="12" md="7" v-if="!mobile">
        <img src="../assets/images/home-bg.jpeg" alt="Login" class="banner" />
      </v-col>
    </v-row>
    <forgot-password
      :visible="forgotPasswordDialog"
      @close="forgotPasswordDialog = false"
    ></forgot-password>
  </div>
</template>

<script>
import { auth } from "../config/firebase";
import helperMixin from "../mixins/helper";
import { employee } from "../service/employees";
import ForgotPassword from "./modals/ForgotPassword.vue";

export default {
  name: "Landing",
  components: { ForgotPassword },
  mixins: [helperMixin],
  data() {
    return {
      email: "",
      password: "",
      show: false,
      forgotPasswordDialog: false,
    };
  },
  methods: {
    async login() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        try {
          const user = await auth.signInWithEmailAndPassword(
            this.email.trim(),
            this.password
          );
          const response = await employee.get(user.user.uid);
          this.$store.dispatch("SET_CURRENT_USER", response);
          localStorage.setItem("user", JSON.stringify(response));

          if (response.isAdmin) this.$router.push("/dashboard");
          else this.$router.push(`/employees/${response.uid}`);
        } catch (error) {
          this.$toast.error(error.message, {
            position: "top",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.login {
  height: 100vh;
}

.row {
  height: 100%;
  margin: 0 !important;
}

.col-md-7 {
  padding: 0 !important;
}

.form {
  padding: 1rem 5rem;
}

form {
  margin-top: 4rem;
}

h1 {
  color: var(--primary-color);
  font-weight: normal;
}

label {
  color: var(--primary-color);
  font-size: 18px;
}

.banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.v-btn {
  width: 160px;
}

@media only screen and (max-width: 600px) {
  .form {
    padding: 2rem;
  }

  h1,
  p {
    text-align: center;
  }

  p {
    font-size: 14px;
  }

  label {
    font-size: 14px;
  }

  .v-btn {
    display: block;
    margin: 0 auto;
  }
}
</style>