<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          placeholder="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3"></v-col>
      <v-col cols="12" md="3">
        <v-select label="Filter by" :items="items" outlined dense></v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="issues"
      :items-per-page="5"
      class="elevation-1"
      :search="search"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.photoURL`]="{ item }">
        <v-avatar color="primary" size="36">
          <span class="white--text text-h6">{{ item.raisedBy.charAt(0) }}</span>
        </v-avatar>
      </template>
      <template v-slot:[`item.raisedAt`]="{ item }">
        {{ item.raisedAt.toDate().toLocaleString() }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getColor(item.status)" class="white--text">{{
          item.status
        }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" icon dark v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="viewRemarks(item.description, item.remarks)">
              <v-list-item-title>View Remarks</v-list-item-title>
            </v-list-item>
            <v-list-item @click="writeRemark(item.docId)">
              <v-list-item-title>Write a Remark</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="currentUser.isAdmin && item.status === 'Open'"
              @click="markAsResolved(item.docId)"
            >
              <v-list-item-title>Mark as resolved</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="currentUser.isAdmin && item.status === 'Resolved'"
              @click="markAsUnResolved(item.docId)"
            >
              <v-list-item-title>Mark as unresolved</v-list-item-title>
            </v-list-item>
            <v-list-item @click="_delete(item.docId)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <issue-remark
      :docId="issueId"
      :visible="remarkDialog"
      @close="remarkDialog = false"
    ></issue-remark>
    <view-remark
      :visible="viewRemarkDialog"
      :issue="issue"
      :remarks="remarks"
      @close="viewRemarkDialog = false"
    ></view-remark>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { issueRef } from "../../config/firebase";
import IssueRemark from "../modals/IssueRemark.vue";
import ViewRemark from "../modals/ViewRemark.vue";
export default {
  components: { IssueRemark, ViewRemark },
  name: "IssuesList",
  data() {
    return {
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "photoURL",
        },
        {
          text: "Raised By",
          align: "start",
          sortable: false,
          value: "raisedBy",
        },
        { text: "Employee ID", value: "empId" },
        { text: "Issue Type", value: "type" },
        { text: "Description", value: "description" },
        { text: "Raised At", value: "raisedAt" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      search: "",
      items: ["All", "Open", "Resolved"],
      issues: [],
      issueId: "",
      remarkDialog: false,
      viewRemarkDialog: false,
      issue: "",
      remarks: [],
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
  created() {
    this.getIssues();
  },
  methods: {
    async getIssues() {
      this.issues = [];
      const currentUser = this.$store.state.user.currentUser;

      if (currentUser.isAdmin) {
        const issuesListSnapshot = await issueRef
          .orderBy("raisedAt", "desc")
          .get();
        issuesListSnapshot.forEach((doc) => {
          this.issues.push({ ...doc.data(), docId: doc.id });
        });
      } else {
        const issuesListSnapshot = await issueRef
          .where("uid", "==", currentUser.uid)
          .orderBy("raisedAt", "desc")
          .get();
        issuesListSnapshot.forEach((doc) => {
          this.issues.push({ ...doc.data(), docId: doc.id });
        });
      }
    },
    getColor(status) {
      switch (status) {
        case "Open":
          return "red";
        case "Resolved":
          return "green";
      }
    },
    async markAsResolved(docId) {
      try {
        await issueRef
          .doc(docId)
          .set({ status: "Resolved", resolvedAt: new Date() }, { merge: true });
        this.$toast.success("Issue Resolved!", {
          position: "top",
        });
      } catch (error) {
        this.$toast.error(error.message, {
          position: "top",
        });
      }
    },
    async markAsUnResolved(docId) {
      try {
        await issueRef.doc(docId).set({ status: "Open" }, { merge: true });
        this.$toast.success("Issue Un Resolved!", {
          position: "top",
        });
      } catch (error) {
        this.$toast.error(error.message, {
          position: "top",
        });
      }
    },
    writeRemark(docId) {
      this.remarkDialog = true;
      this.issueId = docId;
    },
    viewRemarks(issue, remarks) {
      this.viewRemarkDialog = true;
      this.issue = issue;
      this.remarks = remarks;
    },
    async _delete(docId) {
      try {
        await issueRef.doc(docId).delete();
        this.$toast.success("Issue Deleted!", {
          position: "top",
        });
        await this.getIssues();
      } catch (error) {
        this.$toast.error(error.message, {
          position: "top",
        });
      }
    },
  },
};
</script>

<style scoped>
>>> th {
  color: var(--primary-color) !important;
  font-size: 16px !important;
  font-weight: bold !important;
}
</style>