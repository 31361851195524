var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","placeholder":"Search","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}}),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"label":"Filter by","items":_vm.items,"outlined":"","dense":""}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.issues,"items-per-page":5,"search":_vm.search,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.photoURL",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"primary","size":"36"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(item.raisedBy.charAt(0)))])])]}},{key:"item.raisedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.raisedAt.toDate().toLocaleString())+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.getColor(item.status)}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.viewRemarks(item.description, item.remarks)}}},[_c('v-list-item-title',[_vm._v("View Remarks")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.writeRemark(item.docId)}}},[_c('v-list-item-title',[_vm._v("Write a Remark")])],1),(_vm.currentUser.isAdmin && item.status === 'Open')?_c('v-list-item',{on:{"click":function($event){return _vm.markAsResolved(item.docId)}}},[_c('v-list-item-title',[_vm._v("Mark as resolved")])],1):_vm._e(),(_vm.currentUser.isAdmin && item.status === 'Resolved')?_c('v-list-item',{on:{"click":function($event){return _vm.markAsUnResolved(item.docId)}}},[_c('v-list-item-title',[_vm._v("Mark as unresolved")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm._delete(item.docId)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}],null,true)}),_c('issue-remark',{attrs:{"docId":_vm.issueId,"visible":_vm.remarkDialog},on:{"close":function($event){_vm.remarkDialog = false}}}),_c('view-remark',{attrs:{"visible":_vm.viewRemarkDialog,"issue":_vm.issue,"remarks":_vm.remarks},on:{"close":function($event){_vm.viewRemarkDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }