<template>
  <div class="bearer-card">
    <img :src="bearer.photoURL" :alt="bearer.name" v-if="bearer.photoURL" />
    <v-avatar color="secondary" size="150" v-else class="mr-3">
      <span class="white--text text-h2">{{ bearer.name.charAt(0) }}</span>
    </v-avatar>
    <h3 class="mt-3">{{ bearer.name }}</h3>
    <h4>{{ bearer.post }}</h4>
    <div class="bearers-card-actions">
      <v-btn color="primary" @click="edit(bearer.uid)" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn color="error" @click="_delete(bearer.uid)" icon>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "BearerCard",
  props: ["bearer"],
  methods: {
    edit(uid) {
      this.$emit("edit", uid);
    },
    _delete(uid) {
      this.$emit("delete", uid);
    },
  },
};
</script>

<style scoped>
.bearer-card {
  text-align: center;
  width: 220px;
  position: relative;
  cursor: pointer;
}

img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

h3 {
  color: #4f4f4f;
}

h4 {
  color: var(--secondary-color);
}

.bearers-card-actions {
  position: absolute;
  top: -1rem;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.bearer-card:hover .bearers-card-actions {
  opacity: 1;
}
</style>