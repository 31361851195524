<template>
  <div class="stats-card">
    <v-icon color="secondary" large>{{ stat.icon }}</v-icon>
    <div class="contents">
      <h4>{{ stat.title }}</h4>
      <h3>{{ stat.count }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsCard",
  props: ["stat"],
};
</script>

<style scoped>
.stats-card {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  cursor: pointer;
  box-shadow: 0 4px 0 #da6267;
}

@media screen and (max-width: 600px) {
  .contents {
    text-align: right;
  }
}
</style>