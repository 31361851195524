<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="secondary"
          outlined
          class="d-block ml-auto"
          @click="addNewLinkDialog = true"
        >
          <v-icon class="ml-2">mdi-upload</v-icon>
          Add New
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="items.length > 0" class="mt-5">
      <v-col cols="12" md="12" v-for="(item, index) in items" :key="index">
        <link-card :item="item"></link-card>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <div v-else class="text-center">
      <img src="../../assets/images/no-data.png" />
      <div>No data found</div>
    </div>
    <add-new-link
      :visible="addNewLinkDialog"
      @close="addNewLinkDialog = false"
    ></add-new-link>
  </div>
</template>

<script>
import { linksRef } from "../../config/firebase";
import AddNewLink from "../modals/AddNewLink.vue";
import LinkCard from "./LinkCard.vue";
export default {
  components: { LinkCard, AddNewLink },
  data() {
    return {
      items: [],
      addNewLinkDialog: false,
    };
  },
  created() {
    this.getLinks();
  },
  methods: {
    async getLinks() {
      const snapshot = await linksRef.orderBy("createdAt", "desc").get();
      if (snapshot.empty) {
        return;
      }
      snapshot.forEach((doc) => {
        this.items.push({ id: doc.id, ...doc.data() });
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .col-md-4 {
    width: 100%;
    margin-bottom: 1rem !important;
  }
}
</style>