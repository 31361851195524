import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    iconfontsize: "2rem",
    iconcolors: {
      primary: "#5d9cec",
      secondary: "#fafafa",
      default: "#5d9cec",
    },
  },
  theme: {
    themes: {
      light: {
        primary: "#10375c",
        secondary: "#da6267",
      },
    },
  },
});
