<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title class="heading"
        >Raise an issue
        <v-spacer></v-spacer>
        <v-btn icon color="error" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-select
            label="Issue type"
            outlined
            dense
            :items="types"
            v-model="issue.type"
            :rules="rules.type"
          ></v-select>
          <v-textarea
            placeholder="Description"
            dense
            outlined
            v-model="issue.description"
            :rules="rules.description"
          ></v-textarea>
          <v-btn color="secondary" class="d-block mx-auto" @click="submit()"
            >Submit</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { issueRef } from "../../config/firebase";
export default {
  name: "RaiseIssue",
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          return this.$emit("close");
        }
      },
    },
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
  data() {
    return {
      types: ["Welfare", "Policy", "Ameneties", "Anamolies", "Other"],
      issue: {
        raisedBy: "",
        uid: "",
        empId: "",
        type: "",
        description: "",
        raisedAt: "",
        status: "Open",
      },
      rules: {
        type: [(v) => !!v || "Type is required"],
        description: [(v) => !!v || "Description is required"],
      },
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.issue.raisedAt = new Date();
          this.issue.uid = this.currentUser.uid;
          this.issue.empId = this.currentUser.empId;
          this.issue.raisedBy = this.currentUser.name;

          await issueRef.add(this.issue);
          this.$toast.success("Issue submitted!", {
            position: "top",
          });
          this.issue = {
            raisedBy: "",
            uid: "",
            empId: "",
            type: "",
            description: "",
            raisedAt: "",
            status: "Open",
          };

          this.$emit("close");
        } catch (error) {
          this.$toast.error(error.message, {
            position: "top",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
</style>