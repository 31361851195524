<template>
  <v-container>
    <div class="d-flex justify-space-between align-center mb-10">
      <h2>Gallery</h2>
      <v-btn color="primary" @click="$refs.upload.click()" class="mb-5">
        <v-icon class="mr-2">mdi-upload-multiple</v-icon>
        Upload Images
      </v-btn>
    </div>
    <div class="images-wrapper">
      <div
        class="image"
        v-for="(image, imageIndex) in items"
        :key="imageIndex"
        :style="{
          backgroundImage: 'url(' + image.src + ')',
        }"
      >
        <v-btn
          icon
          color="error"
          @click="deleteImage(image.id)"
          class="delete-image"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <input
      type="file"
      multiple
      ref="upload"
      @change="uploadImages"
      hidden
      accept="image/*"
    />
    <progrss-loader
      :visible="loading"
      :loaderText="loaderText"
    ></progrss-loader>
  </v-container>
</template>

<script>
import ProgrssLoader from "../components/modals/ProgrssLoader.vue";
import { galleryRef } from "../config/firebase";
import uploadFile from "../mixins/uploadFile";

export default {
  components: { ProgrssLoader },
  data() {
    return {
      loading: false,
      loaderText: "",
      items: [],
      index: null,
    };
  },
  mixins: [uploadFile],
  created() {
    this.getImages();
  },
  methods: {
    getImages() {
      this.items = [];
      this.loading = true;
      this.loaderText = "Loading images...";
      galleryRef
        .orderBy("createdAt", "desc")
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            this.loading = false;
            return;
          }
          this.items = [];
          snapshot.forEach((doc) => {
            this.items.push({
              id: doc.id,
              src: doc.data().src,
            });
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async uploadImages(e) {
      this.loading = true;
      this.loaderText = "Uploading images";
      const files = e.target.files;
      try {
        for (let file of files) {
          const imgUrl = await this.uploadFile("gallery/", file);
          await galleryRef.add({ src: imgUrl, createdAt: new Date() });
        }
        this.$refs.upload.value = "";
        this.loading = false;
        this.loaderText = "";
        this.$toast.open({
          message: "Images uploaded successfully",
          type: "success",
          position: "top",
        });
        this.getImages();
      } catch (error) {
        this.loading = false;
        this.loaderText = "";
        this.$toast.open({
          message: error.message,
          type: "error",
          position: "top",
        });
      }
    },
    async deleteImage(id) {
      this.loading = true;
      this.loaderText = "Deleting image";
      try {
        await galleryRef.doc(id).delete();
        this.loading = false;
        this.loaderText = "";
        this.$toast.open({
          message: "Image deleted successfully",
          type: "success",
          position: "top",
        });
        this.getImages();
      } catch (error) {
        this.loading = false;
        this.loaderText = "";
        this.$toast.open({
          message: error.message,
          type: "error",
          position: "top",
        });
      }
    },
  },
};
</script>

<style scoped>
h2 {
  color: #4f4f4f;
  font-weight: 500;
}

.images-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.images-wrapper .image {
  width: 32%;
  height: 300px;
  background-size: cover;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5rem;
  position: relative;
}

.images-wrapper .image:last-child {
  margin-right: 0;
}

.delete-image {
  position: absolute;
  top: -0;
  right: 0;
}

.images-wrapper .image:hover .delete-image {
  display: block;
}
</style>