<template>
  <v-container>
    <div class="d-flex justify-space-between align-center">
      <h2>{{ currentUser.isAdmin ? "Issues" : "My Issues" }}</h2>
    </div>
    <issues-list></issues-list>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import IssuesList from "../components/issues/IssuesList.vue";
export default {
  components: { IssuesList },
  name: "Issues",
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
};
</script>

<style scoped>
h2 {
  color: #4f4f4f;
  font-weight: 500;
}
</style>