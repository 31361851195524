import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import Employees from "../views/Employees.vue";
import Issues from "../views/Issues.vue";
import OfficeBearers from "../views/OfficeBearers.vue";
import Appeal from "../views/Appeal.vue";
import Profile from "../views/Profile.vue";
import Reports from "../views/Reports.vue";
import Links from "../views/Links.vue";
import FileLinks from "../views/FileLinks.vue";
import Gallery from "../views/Gallery.vue";

import { isLoggedIn } from "./auth-guard";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/employees",
    name: "Employees",
    component: Employees,
  },
  {
    path: "/issues",
    name: "Issues",
    component: Issues,
  },
  {
    path: "/office-bearers",
    name: "OfficeBearers",
    component: OfficeBearers,
  },
  {
    path: "/appeal",
    name: "Appeal",
    component: Appeal,
  },
  {
    path: "/employees/:id",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/links",
    name: "Links",
    component: Links,
  },
  {
    path: "/file-links",
    name: "FileLinks",
    component: FileLinks,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = isLoggedIn();
  if (to.name !== "Home" && !isAuthenticated) {
    next({ name: "Home" });
  } else {
    const data = isAuthenticated.data;
    store.dispatch("SET_CURRENT_USER", data);
    next();
  }
});

export default router;
