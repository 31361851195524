<template>
  <v-form ref="form">
    <v-text-field
      outlined
      label="Employee Name"
      dense
      type="text"
      v-model="employee.name"
      :rules="rules.name"
    ></v-text-field>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          label="Employee ID"
          dense
          type="text"
          v-model="employee.empId"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          outlined
          :items="grades"
          dense
          label="Select grade"
          v-model="employee.grade"
        ></v-select>
      </v-col>
    </v-row>
    <v-text-field
      outlined
      label="Email address"
      dense
      type="email"
      v-model="employee.email"
      :rules="rules.email"
    ></v-text-field>
    <v-text-field
      outlined
      label="Mobile Number"
      dense
      type="text"
      v-model="employee.phoneNumber"
    ></v-text-field>
    <v-text-field
      outlined
      label="Password"
      dense
      :type="show ? 'text' : 'password'"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="show = !show"
      v-model="employee.password"
      :rules="rules.password"
    ></v-text-field>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          outlined
          :items="genders"
          dense
          label="Select gender"
          v-model="employee.gender"
          :rules="rules.gender"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          label="Employee age"
          dense
          type="number"
          v-model="employee.age"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-btn color="secondary" class="d-block mx-auto" @click="register()"
      >Register</v-btn
    >
  </v-form>
</template>

<script>
import { employee } from "../../service/employees";
import { auth } from "../../config/firebase";
import { mapState } from "vuex";
import uploadFile from "../../mixins/uploadFile";

export default {
  name: "Register",
  props: ["employeeProp", "isEditProp"],
  data() {
    return {
      genders: ["Male", "Female", "Other"],
      grades: [
        "S1",
        "S2",
        "S3",
        "S4",
        "TS1",
        "TS2",
        "TS3",
        "TS4",
        "TS5",
        "TS6",
        "JM1",
        "JM2",
        "JM3",
        "JM4",
        "JM5",
        "JM6",
      ],
      employee: {
        name: "",
        empId: "",
        grade: "",
        email: "",
        password: "",
        phoneNumber: "",
        gender: "",
        age: "",
        photoURL: "",
        createdAt: "",
        isAdmin: false,
      },
      rules: {
        name: [(v) => !!v || "Name is required"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        empId: [(v) => !!v || "Employee ID is required"],
        grade: [(v) => !!v || "Grade is required"],
        phoneNumber: [
          (v) => !!v || "Mobile number is required",
          (v) => /^[6-9]\d{9}$/.test(v) || "Mobile number must be valid",
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && v.length >= 6) ||
            "Password must be atleast 6 characters long",
        ],
        gender: [(v) => !!v || "Gender is required"],
        age: [(v) => !!v || "Age is required"],
      },
      show: false,
      isEdit: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
    watchEmployeePropValue: function() {
      return this.employeeProp;
    }
  },
  watch: {
    watchEmployeePropValue: function(newVal, oldVal) {
      this.employee = newVal;
      this.isEdit = this.isEditProp;
    }
  },
  methods: {
    async register() {
      if (this.$refs.form.validate()) {
        try {
          this.employee.createdAt = new Date();
          const user = await auth.createUserWithEmailAndPassword(
            this.employee.email.trim(),
            this.employee.password
          );
          delete this.employee.password;
          this.employee.uid = user.user.uid;
          if (this.employee.photoURL) {
            this.employee.photoURL = await uploadFile(
              "/employees",
              this.employee.photoURL
            );
          }
          const response = await employee.create(this.employee);
          console.log(response);

          if (this.currentUser !== null) {
            this.$toast.success("Employee Added!", {
              position: "top",
            });
          } else {
            this.$toast.success("Registration succesful!", {
              position: "top",
            });
            await auth.signOut();
            this.$router.push("/");
          }

          this.employee = {
            name: "",
            empId: "",
            grade: "",
            email: "",
            password: "",
            phoneNumber: "",
            gender: "",
            age: "",
            photoURL: "",
            createdAt: "",
            isAdmin: false,
          };
          this.$emit("close");
        } catch (error) {
          this.$toast.error(error.message, {
            position: "top",
          });
        }
      }
    },
  },
};
</script>

<style scoped>
</style>