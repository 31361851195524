<template>
  <div>
    <v-dialog v-model="show" width="600">
      <v-card>
        <v-card-title class="heading"
          >{{ isEdit ? "Edit" : "Add" }} {{ typeMap[type] }}
          <v-spacer></v-spacer>
          <v-btn icon color="error" @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-autocomplete
              outlined
              placeholder="Employee Name"
              dense
              type="text"
              v-model="localEmployee.name"
              :items="employees"
              item-text="name"
              return-object
              @change="handleEmployeeNameChange"
              :disabled="isEdit"
            ></v-autocomplete>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  placeholder="Employee ID"
                  dense
                  type="text"
                  v-model="localEmployee.empId"
                  :disabled="isEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  outlined
                  :items="grades"
                  dense
                  placeholder="Select grade"
                  v-model="localEmployee.grade"
                  :disabled="isEdit"
                ></v-select>
              </v-col>
            </v-row>
            <v-text-field
              outlined
              placeholder="Email address"
              dense
              type="email"
              v-model="localEmployee.email"
              :disabled="isEdit"
            ></v-text-field>
            <v-text-field
              outlined
              placeholder="Mobile Number"
              dense
              type="text"
              v-model="localEmployee.phoneNumber"
              :disabled="isEdit"
            ></v-text-field>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  outlined
                  :items="genders"
                  dense
                  placeholder="Select gender"
                  v-model="localEmployee.gender"
                  :disabled="isEdit"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  placeholder="Employee age"
                  dense
                  type="number"
                  v-model="localEmployee.age"
                  :disabled="isEdit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-file-input
                  label="Photo"
                  outlined
                  dense
                  accept="image/*"
                  v-model="localEmployee.photoURL"
                ></v-file-input>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="posts[type]"
                  v-model="localEmployee.post"
                  outlined
                  label="Post"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-btn
              color="secondary"
              class="d-block mx-auto"
              @click="addNewOfficeBearer()"
            >
              Submit
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <progrss-loader
      :visible="loading"
      :loaderText="loaderText"
    ></progrss-loader>
  </div>
</template>

<script>
import { employee } from "../../service/employees";
import uploadFile from "../../mixins/uploadFile";
import ProgrssLoader from "./ProgrssLoader.vue";
import { executiveCommitteRef, officeBearersRef, representationInCommitteesRef } from "../../config/firebase";

export default {
  components: { ProgrssLoader },
  props: ["visible", "type", "isEdit", "employee"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          return this.$emit("close");
        }
      },
    },
    localEmployee: {
      get() {
        return this.employee;
      },
      set(value) {
        this.$emit("update:employee", value);
      },
    },
  },
  created() {
    this.getEmployees();
  },
  mixins: [uploadFile],
  data() {
    return {
      loading: false,
      loaderText: "",
      genders: ["Male", "Female", "Other"],
      posts: {
        isBearer: [
          "President",
          "Vice President",
          "General secretery",
          "Joint secretery",
          "Treasurer",
        ],
        isExecutive: [
          "President",
          "Vice President",
          "General secretery",
          "Joint secretery",
          "Treasurer",
        ],
        isRepresentation: [
          "MDCPS COMMITTE",
          "WORK COMMITTE",
          "BENEVOLENT FUND COMMITTEE",
          "CANTEEN COMMITTE",
          "TOWNSHIP COMMITTEE",
        ],
      },

      grades: [
        "S1",
        "S2",
        "S3",
        "S4",
        "T1",
        "T2",
        "T3",
        "T4",
        "TS1",
        "TS2",
        "TS3",
        "TS4",
        "TS5",
        "TS6",
        "JM1",
        "JM2",
        "JM3",
        "JM4",
        "JM5",
        "JM6",
      ],
      typeMap: {
        isBearer: "Office Bearer",
        isExecutive: "Executive Committe",
        isRepresentation: "Representation in Committee",
      },
      employees: [],
      emp: {},
    };
  },
  methods: {
    async getEmployees() {
      if(!this.isEdit) {
        this.employees = await employee.getAll();
        const grades = [...new Set(this.employees.map((emp) => emp.grade))]
          .sort()
          .reverse();
        this.grades = grades;
      }
    },
    handleEmployeeNameChange(emp) {
      this.localEmployee = emp;
    },
    async addNewOfficeBearer() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.loaderText = "Updating...";
          this.employee.updateddAt = new Date();
          if (
            this.employee.photoURL &&
            typeof this.employee.photoURL === "object"
          )
            this.employee.photoURL = await this.uploadFile(
              "photos/",
              this.employee.photoURL
            );
          if (this.type === "isBearer") {
            if (this.isEdit) {
              await officeBearersRef
                .doc(this.employee.uid)
                .set(this.employee, { merge: true });
            } else {
              await officeBearersRef.add(this.employee);
            }
          } else if (this.type === "isExecutive") {
            if (this.isEdit) {
              await executiveCommitteRef
                .doc(this.employee.uid)
                .set(this.employee, { merge: true });
            } else {
              await executiveCommitteRef.add(this.employee);
            }
          } else if (this.type === "isRepresentation") {
            if (this.isEdit) {
              await representationInCommitteesRef
                .doc(this.employee.uid)
                .set(this.employee, { merge: true });
            } else {
              await representationInCommitteesRef.add(this.employee);
            }
          }
          this.$toast.success("Updated!", {
            position: "top",
          });
          this.show = false;
          this.loading = false;
          this.$emit("closeAndRefresh");
        } catch (error) {
          this.$toast.error(error.message, {
            position: "top",
          });
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style>
</style>