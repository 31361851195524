<template>
  <v-form ref="form">
    <v-dialog v-model="show" persistent width="500px">
      <v-card>
        <v-card-title>
          <span class="heading">Forgot Password</span>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="show = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Email"
            type="email"
            required
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="sendPasswordResetEmail"
            >Send Email</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { auth } from "../../config/firebase";

export default {
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      email: "",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
    };
  },
  methods: {
    sendPasswordResetEmail() {
      if (!this.$refs.form.validate()) return;
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.$toast.open({
            message: `Password reset email sent to ${this.email}`,
            type: "success",
            position: "top",
          });
          this.show = false;
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: "error",
            position: "top",
          });
        });
    },
  },
};
</script>

<style>
</style>