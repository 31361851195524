<template>
  <v-container>
    <div
      v-for="(administrationType, index) in administrationTypes"
      :key="index"
    >
      <div class="d-flex justify-space-between align-center mt-20">
        <h2>{{ administrationType.title }}</h2>
        <v-btn color="primary" @click="handleAdd(administrationType.key)">
          <v-icon class="mr-2">mdi-plus-circle</v-icon>
          Add new
        </v-btn>
      </div>
      <bearers-list
        class="mt-5"
        :type="administrationType.key"
        @edit="handleEdit"
        @delete="handleDelete"
        :administration="administration"
      ></bearers-list>
    </div>

    <add-new-office-bearer
      :visible="dialog"
      :type="type"
      @close="handleAddAdministrationModalClose"
      :employee="employee"
      :isEdit="isEdit"
      @closeAndRefresh="handleModalCloseAndRefresh"
      @update:employee="handleUpdateEmployee"
    ></add-new-office-bearer>
  </v-container>
</template>

<script>
import AddNewOfficeBearer from "../components/modals/AddNewOfficeBearer.vue";
import BearersList from "../components/office-bearers/BearersList.vue";
import {
  executiveCommitteRef,
  officeBearersRef,
  representationInCommitteesRef,
} from "../config/firebase";
import { employee } from "../service/employees";
export default {
  name: "OfficeBearers",
  components: {
    AddNewOfficeBearer,
    BearersList,
  },
  data() {
    return {
      dialog: false,
      type: "",
      isEdit: false,
      employee: {
        name: "",
        empId: "",
        grade: "",
        email: "",
        phoneNumber: "",
        gender: "",
        age: "",
        photoURL: "",
        createdAt: "",
        isAdmin: false,
        isBearer: false,
        isExecutive: false,
        isRepresentation: false,
        post: "",
      },
      administrationTypes: [
        {
          title: "Office Bearers",
          key: "isBearer",
        },
        {
          title: "Executive Committe",
          key: "isExecutive",
        },
        {
          title: "Representation in committees",
          key: "isRepresentation",
        },
      ],
      administration: {
        bearers: [],
        executives: [],
        representations: [],
      },
      administrationKeyVarMap: {
        isBearer: "bearers",
        isExecutive: "executives",
        isRepresentation: "representations",
      },
      postOrder: {
        President: 1,
        "Vice President": 2,
        "General secretery": 3,
        "Joint secretery": 4,
        Treasurer: 5,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleAddAdministrationModalClose() {
      this.isEdit = false;
      this.employee = {};
      this.dialog = false;
    },
    handleAdd(type) {
      this.employee = {};
      this.type = type;
      this.dialog = true;
    },
    handleEdit({ uid, type }) {
      this.isEdit = true;
      const data = this.administration[this.administrationKeyVarMap[type]].find(
        (bearer) => bearer.uid === uid
      );
      this.type = type;
      this.employee = data;
      this.dialog = true;
    },
    async handleDelete({ uid, type }) {
      try {
        if (
          window.confirm(`Are you sure you want to delete ${employee.name}?`)
        ) {
          if (type === "isBearer") {
            await officeBearersRef.doc(uid).delete();
            await this.getBearers();
          } else if (type === "isExecutive") {
            await executiveCommitteRef.doc(uid).delete();
            await this.getExecutives();
          } else if (type === "isRepresentation") {
            await representationInCommitteesRef.doc(uid).delete();
            await this.getRepresentations();
          }
          this.$toast.success("Deleted successfully");
        }
      } catch (error) {
        this.$toast.error(error.message);
      }
    },
    processSnapshot(snapshot, type) {
      return new Promise((resolve) => {
        let count = 0;
        snapshot.forEach((doc) => {
          this.administration[type].push({ ...doc.data(), uid: doc.id });
          count++;
          // Check if it's the last item in the array
          if (count === snapshot.size) {
            resolve(this.administration[type]); // Resolve the promise when the last item is processed
          }
        });
      });
    },
    async getBearers() {
      this.administration.bearers = [];
      const employeesSnapshot = await officeBearersRef.get();

      const documents = await this.processSnapshot(
        employeesSnapshot,
        "bearers"
      );
      // Sort the array based on the postOrder
      documents.sort((a, b) => this.postOrder[a.post] - this.postOrder[b.post]);
      this.administration.bearers = documents;
    },
    async getExecutives() {
      this.administration.executives = [];
      const employeesSnapshot = await executiveCommitteRef.get();

      const documents = await this.processSnapshot(
        employeesSnapshot,
        "executives"
      );
      // Sort the array based on the postOrder
      documents.sort((a, b) => {
        const nameA = a.name.toLowerCase(); // Convert to lowercase for case-insensitive sorting
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      });
      this.administration.executives = documents;
    },
    async getRepresentations() {
      this.administration.representations = [];
      const employeesSnapshot = await representationInCommitteesRef.get();

      employeesSnapshot.forEach((doc) => {
        this.administration.representations.push({
          ...doc.data(),
          uid: doc.id,
        });
      });

      const sortingArr = [
        "MDCPS COMMITTE",
        "WORK COMMITTE",
        "BENEVOLENT FUND COMMITTEE",
        "CANTEEN COMMITTE",
        "TOWNSHIP COMMITTEE",
      ];
      const orderMap = new Map();
      sortingArr.forEach((post, index) => {
        orderMap.set(post, index);
      });

      this.administration.representations.sort((a, b) => {
        const postA = a.post;
        const postB = b.post;

        const orderA = orderMap.get(postA);
        const orderB = orderMap.get(postB);

        return orderA - orderB;
      });
    },
    getData() {
      this.getBearers();
      this.getExecutives();
      this.getRepresentations();
    },
    handleModalCloseAndRefresh() {
      this.dialog = false;
      this.getData();
    },
    handleUpdateEmployee(newEmployee) {
      this.employee = newEmployee;
    },
  },
};
</script>

<style scoped>
h2 {
  color: #4f4f4f;
  font-weight: 500;
}
</style>