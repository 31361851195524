import firebase from "firebase";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCq1JGbXvmL-VyStbDFH-LlrhzbzUuybGQ",
  authDomain: "mrpl-f291a.firebaseapp.com",
  projectId: "mrpl-f291a",
  storageBucket: "mrpl-f291a.appspot.com",
  messagingSenderId: "581888988967",
  appId: "1:581888988967:web:c1eca1c3129b54083dfb02",
  measurementId: "G-XVJ3N78MH4",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();
const db = firebase.firestore();
const storageRef = firebase.storage().ref();
const fieldValueRef = firebase.firestore.FieldValue;

//collections
const employeeRef = db.collection("employees");
const issueRef = db.collection("issues");
const reportsRef = db.collection("reports");
const linksRef = db.collection("links");
const fileLinksRef = db.collection("fileLinks");
const galleryRef = db.collection("gallery");
const officeBearersRef = db.collection("officeBearers");
const executiveCommitteRef = db.collection("executiveCommittee");
const representationInCommitteesRef = db.collection(
  "representationInCommittees"
);

export {
  auth,
  db,
  employeeRef,
  issueRef,
  fieldValueRef,
  storageRef,
  reportsRef,
  linksRef,
  fileLinksRef,
  galleryRef,
  officeBearersRef,
  executiveCommitteRef,
  representationInCommitteesRef,
};
