<template>
  <section>
    <div class="profile">
      <div class="overlay"></div>
      <div class="content d-flex">
        <img
          :src="item.photoURL"
          :alt="item.name"
          v-if="item.photoURL"
          class="mr-5 profile-photo"
        />
        <v-avatar color="secondary" size="150" v-else class="mr-3">
          <span class="white--text text-h2">{{
            item && item.name ? item.name.charAt(0) : null
          }}</span>
        </v-avatar>
        <div class="details">
          <h2>{{ item.name }}</h2>
          <span>{{ item.post ? item.post : "Employee" }}</span>
        </div>
      </div>
      <div
        class="action"
        @click="raiseIssueDialog = true"
        v-if="!currentUser.isAdmin"
      >
        <v-btn color="secondary" outlined>Raise an issue</v-btn>
      </div>
    </div>
    <v-tabs
      v-model="tab"
      align-with-title
      class="tab-section"
      center-active
      grow
    >
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab> My Complaints </v-tab>
      <v-tab> Important Links </v-tab>
      <v-tab> Reports </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-text v-if="issues.length > 0">
            <issue-card
              v-for="(issue, index) in issues"
              :key="index"
              :item="issue"
              class="mb-5"
              @refresh="getIssues"
            ></issue-card>
          </v-card-text>
          <v-card-text v-else class="mt-10">
            <img
              src="../assets/images/no-data.png"
              alt="No Data"
              class="d-block mx-auto"
            />
            <h3 class="text-center">No data found</h3>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4"> </v-col>
              <v-col cols="12" md="4"> </v-col>
              <v-col cols="12" md="4">
                <v-select
                  outlined
                  dense
                  label="Filter by"
                  :items="types"
                  class="ml-auto"
                  @change="handleFilter"
                  v-model="type"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="links.length > 0" class="mt-10">
            <ul>
              <li v-for="(link, index) in links" :key="index" class="mb-3">
                <a :href="link.link" target="_blank">
                  {{ link.title ? link.title : cropLink(link.link) }}
                </a>
              </li>
            </ul>
          </v-card-text>
          <v-card-text v-else class="mt-10">
            <img
              src="../assets/images/no-data.png"
              alt="No Data"
              class="d-block mx-auto"
            />
            <h3 class="text-center">No data found</h3>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card-text v-if="reports.length > 0" class="mt-10">
          <ul>
            <li v-for="(report, index) in reports" :key="index" class="mb-3">
              <a :href="report.file" target="_blank">
                {{ report.title ? report.title : cropLink(report.title) }}
              </a>
            </li>
          </ul>
        </v-card-text>
        <v-card-text v-else class="mt-10">
          <img
            src="../assets/images/no-data.png"
            alt="No Data"
            class="d-block mx-auto"
          />
          <h3 class="text-center">No data found</h3>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <raise-issue :visible="raiseIssueDialog" @close="handleClose"></raise-issue>
  </section>
</template>

<script>
import { mapState } from "vuex";
import IssueCard from "../components/issues/IssueCard.vue";
import RaiseIssue from "../components/modals/RaiseIssue.vue";
import { fileLinksRef, issueRef, reportsRef } from "../config/firebase";
import { employee } from "../service/employees";
export default {
  components: { IssueCard, RaiseIssue },
  data() {
    return {
      item: {},
      tab: null,
      issues: [],
      raiseIssueDialog: false,
      links: [],
      types: [
        "ALL",
        "MRPL CIRCULARS",
        "LETTER TO MGMT",
        "LETTER TO MEU",
        "MINUTES OF EC",
        "STRUCTURED MEETINGS",
        "REPORTS",
      ],
      type: "ALL",
      tempLinks: [],
      reports: [],
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    cropLink(link) {
      return link.length > 50 ? link.substring(0, 50) + "..." : link;
    },
    handleFilter() {
      if (this.type === "ALL") {
        this.links = this.tempLinks;
      } else {
        this.links = this.tempLinks.filter((link) => {
          return link.category === this.type;
        });
      }
    },
    async getData() {
      try {
        this.item = await employee.get(this.$route.params.id);
        this.getIssues();
        this.getLinks();
        this.getReports();
      } catch (error) {
        console.log(error);
      }
    },
    async getIssues() {
      try {
        const documents = await issueRef
          .where("uid", "==", this.item.uid)
          .get();
        this.issues = documents.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    processSnapshot(snapshot) {
      return new Promise((resolve) => {
        let count = 0;
        snapshot.forEach((doc) => {
          this.links.push({ ...doc.data(), id: doc.id });
          count++;
          // Check if it's the last item in the array
          if (count === snapshot.size) {
            resolve(this.links); // Resolve the promise when the last item is processed
          }
        });
      });
    },
    async getLinks() {
      try {
        const fileLinksSnapshot = await fileLinksRef
          .orderBy("createdAt", "desc")
          .get();
        const documents = await this.processSnapshot(fileLinksSnapshot);
        documents.sort((a, b) => {
          const numA = parseInt(a.title.match(/\d+/)[0], 10);
          const numB = parseInt(b.title.match(/\d+/)[0], 10);

          return numA - numB;
        });
        this.links = documents.reverce();
        this.tempLinks = documents.reverce();
      } catch (error) {
        console.log(error);
      }
    },
    async getReports() {
      try {
        const reportsSnapshot = await reportsRef.get();
        this.reports = reportsSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async handleClose() {
      this.raiseIssueDialog = false;
      await this.getIssues();
    },
  },
};
</script>

<style scoped>
.profile {
  background-image: url("../assets/images/meu-bg.jpg");
  height: 300px;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.overlay {
  position: absolute;
  background: rgba(16, 55, 92, 0.8);
  width: 100%;
  height: 100%;
}

.profile-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--primary-color);
}

.content {
  position: absolute;
  bottom: -25%;
  left: 10%;
}

h2,
span {
  color: var(--primary-color);
}

.details {
  margin-top: 25%;
}

.action {
  position: absolute;
  right: 5px;
  bottom: -20%;
}

.tab-section {
  margin-top: 10%;
}

@media only screen and (max-width: 600px) {
  .profile {
    height: 200px;
    background-size: cover;
    background-position: center;
  }

  .content {
    left: 5%;
    bottom: -18%;
    align-items: center;
  }

  .details {
    margin-top: 0;
  }

  .details > h2 {
    font-size: 20px;
    color: #fff;
  }

  .v-avatar {
    width: 80px !important;
    min-width: 80px !important;
    height: 80px !important;
  }

  .tab-section {
    margin-top: 35%;
  }

  .action {
    bottom: -50%;
  }
}
</style>