<template>
  <div>
    <v-dialog v-model="show" width="600">
      <v-form ref="form">
        <v-card>
          <v-card-title class="heading">
            Upload New Link
            <v-spacer></v-spacer>
            <v-btn text @click="show = false" color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="mt-5">
            <v-text-field
              v-model="form.title"
              label="Title"
              required
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="form.link"
              :rules="[(v) => !!v || 'Link is required']"
              label="Link"
              outlined
              dense
              type="url"
            ></v-text-field>
            <!-- <v-textarea
              v-model="form.description"
              label="Description"
              outlined
            ></v-textarea> -->
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" class="d-block mx-auto" @click="upload"
              >Upload</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <progrss-loader
      :loading="loading"
      :loaderText="loaderText"
    ></progrss-loader>
  </div>
</template>

<script>
import { linksRef } from "../../config/firebase";
import ProgrssLoader from "./ProgrssLoader.vue";
export default {
  components: { ProgrssLoader },
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) this.$emit("close", value);
      },
    },
  },
  data() {
    return {
      form: {
        title: "",
        // description: "",
        link: "",
      },
      loading: false,
      loaderText: "Uploading...",
    };
  },
  methods: {
    async upload() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.form.createdAt = new Date();
          this.form.link = this.form.link.replace(/(^\w+:|^)\/\//, "");

          await linksRef.add(this.form);
          this.show = false;
          this.form = {
            title: "",
            // description: "",
            link: "",
          };
          this.loading = false;
          this.$toast.open({
            message: "Link uploaded successfully",
            type: "success",
            position: "top",
            duration: 5000,
          });
        } catch (error) {
          this.loading = false;
          this.$toast.open({
            message: error.message,
            position: "top",
            type: "error",
          });
        }
      }
    },
  },
};
</script>

<style>
</style>