<template>
  <v-card elevation="1">
    <v-card-text>
      <div class="actions">
        <v-btn
          icon
          color="red"
          class="d-block ml-auto"
          @click="_delete(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <div class="content" @click="download(item.file)">
        <v-row>
          <v-col cols="12" md="4">
            <img
              src="../../assets/images/pdf.svg"
              :alt="item.title"
              width="50px"
            />
          </v-col>
          <v-col cols="12" md="8">
            <h3 class="mt-2">{{ item.title }}</h3>
            <p class="mt-3">{{ cropDesc(item.description) }}</p>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { reportsRef } from "../../config/firebase";
export default {
  props: ["item"],
  methods: {
    cropDesc(desc) {
      if (desc.length > 30) {
        return desc.substring(0, 30) + "...";
      }
      return desc;
    },
    download(file) {
      window.open(file, "_blank");
    },
    async _delete(id) {
      try {
        await reportsRef.doc(id).delete();
        this.$toast.open({
          message: "Report deleted successfully",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: "Error deleting report",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  border: 0px;
  border: 1px solid var(--primary-color);
  height: 150px;
  overflow: hidden;
}

p {
  text-align: justify;
}

.content {
  cursor: pointer;
}

.actions {
  display: none;
  width: 100%;
}

.v-card:hover .actions {
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  h4 {
    font-size: 18px;
    margin-top: 1rem !important;
  }

  p {
    font-size: 14px;
  }

  img {
    width: 40px;
  }
}
</style>