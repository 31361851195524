<template>
  <v-dialog v-model="show" width="600">
    <v-card>
      <v-card-title class="heading"
        >Add New Employee
        <v-spacer></v-spacer>
        <v-btn icon color="error" @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <register @close="show = false"></register>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Register from "../auth/Register.vue";
export default {
  components: { Register },
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          return this.$emit("close");
        }
      },
    },
  },
};
</script>

<style>
</style>