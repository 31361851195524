<template>
  <div class="d-flex">
    <a :href="'//' + item.link" target="_blank">{{
      item.title ? item.title : item.link
    }}</a>
    <v-btn icon color="red" class="d-block ml-auto" @click="_delete(item.id)">
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { linksRef } from "../../config/firebase";
export default {
  props: ["item"],
  methods: {
    cropDesc(desc) {
      if (desc.length > 30) {
        return desc.substring(0, 30) + "...";
      }
      return desc;
    },
    async _delete(id) {
      try {
        await linksRef.doc(id).delete();
        this.$toast.open({
          message: "Link deleted successfully",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: "Error deleting link",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>

@media only screen and (max-width: 600px) {
  h4 {
    font-size: 18px;
    margin-top: 1rem !important;
  }

  p {
    font-size: 14px;
  }

  img {
    width: 40px;
  }
}
</style>