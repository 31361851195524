<template>
  <div>
    <v-app-bar color="white" dark elevation="0">
      <v-app-bar-nav-icon
        color="secondary"
        @click.stop="sidebarMenu = !sidebarMenu"
        v-if="currentUser.isAdmin"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center" v-if="!currentUser.isAdmin">
        <v-img
          alt="MRPL Logo"
          class="shrink mr-2"
          contain
          src="../assets/images/logo.svg"
          transition="scale-transition"
          width="60"
        />
        <h2 class="black--text">MEU</h2>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn text v-if="!currentUser.isAdmin" @click="raiseIssueDialog = true"
        >Raise an issue</v-btn
      > -->
      <v-avatar color="primary" class="mr-3">
        <span class="white--text text-h6">{{
          currentUser.name.charAt(0)
        }}</span>
      </v-avatar>
      <v-btn @click="logout()" color="secondary" depressed>
        <v-icon class="mr-2">mdi-logout</v-icon>
        Logout
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="sidebarMenu"
      app
      floating
      :permanent="sidebarMenu"
      v-if="currentUser.isAdmin"
      :mini-variant.sync="mini"
    >
      <v-list dense color="white" dark>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <div class="d-flex align-center">
                <v-img
                  alt="MRPL Logo"
                  class="shrink mr-2"
                  contain
                  src="../assets/images/logo.svg"
                  transition="scale-transition"
                  width="60"
                />
                <h2 class="black--text">MEU</h2>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.href"
        >
          <v-list-item-icon>
            <v-icon color="secondary">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <raise-issue
      :visible="raiseIssueDialog"
      @close="raiseIssueDialog = false"
    ></raise-issue>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { auth } from "../config/firebase";
import RaiseIssue from "./modals/RaiseIssue.vue";
export default {
  components: { RaiseIssue },
  name: "Layout",
  computed: {
    ...mapState({
      currentUser: (state) => state.user.currentUser,
    }),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
  },
  data: () => ({
    sidebarMenu: true,
    items: [
      { title: "Dashboard", href: "/dashboard", icon: "mdi-home-outline" },
      {
        title: "Employees",
        href: "/employees",
        icon: "mdi-account-group-outline",
      },
      { title: "Issues", href: "/issues", icon: "mdi-account-alert-outline" },
      {
        title: "Administration",
        href: "/office-bearers",
        icon: "mdi-account-multiple-check-outline",
      },
      { title: "Reports", href: "/reports", icon: "mdi-chart-box-outline" },
      { title: "Links", href: "/links", icon: "mdi-link" },
      { title: "Gallery", href: "/gallery", icon: "mdi-image-multiple" },
      { title: "Member Corner", href: "/file-links", icon: "mdi-file-link" },
    ],
    raiseIssueDialog: false,
  }),
  methods: {
    async logout() {
      await auth.signOut();
      this.$store.dispatch("SET_CURRENT_USER", null);
      localStorage.clear();
      this.$router.push("/");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  },
};
</script>

<style scoped>
header {
  height: 80px !important;
}
</style>

