import { employeeRef, issueRef } from "../config/firebase";

export default {
    created() {
        this.getStats();
    },
    data() {
        return {
            stats: [
                {
                    title: "Total Employees",
                    icon: "mdi-account-multiple-outline",
                    count: 0,
                },
                {
                    title: "Issues Raised",
                    icon: "mdi-alert-outline",
                    count: 0,
                },
                {
                    title: "Issues Solved",
                    icon: "mdi-alert-circle-check-outline",
                    count: 0,
                },
                {
                    title: "Issues pending",
                    icon: "mdi-alert-minus-outline",
                    count: 0,
                },
            ],
        }
    },
    methods: {
        async getStats() {
            let documents = await employeeRef.get();
            this.stats[0].count = documents.size;

            documents = await issueRef.get();

            const issues = []
            documents.forEach(doc => {
                issues.push(doc.data());
            });

            this.stats[1].count = issues.length;
            const resolved = issues.filter(issue => issue.status === "Resolved");
            this.stats[2].count = resolved.length;

            const pending = issues.filter(issue => issue.status === "Open");
            this.stats[3].count = pending.length;
        }
    }
}