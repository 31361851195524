export default {
    state: {
        currentUser: null,
    },
    mutations: {
        UPDATE_CURRENT_USER(state, payload) {
            state.currentUser = payload;
        },
    },
    actions: {
        SET_CURRENT_USER(context, payload) {
            context.commit('UPDATE_CURRENT_USER', payload);
        }
    },
}