<template>
  <div>
    <v-dialog v-model="show" width="600">
      <v-form ref="form">
        <v-card>
          <v-card-title class="heading">
            Upload New Report
            <v-spacer></v-spacer>
            <v-btn text @click="show = false" color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="mt-5">
            <v-text-field
              v-model="form.title"
              :rules="[(v) => !!v || 'Title is required']"
              label="Title"
              required
              outlined
              dense
            ></v-text-field>
            <v-file-input
              v-model="form.file"
              :rules="[(v) => !!v || 'File is required']"
              label="File"
              outlined
              accept="application/*"
              dense
            ></v-file-input>
            <v-textarea
              v-model="form.description"
              label="Description"
              outlined
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary" class="d-block mx-auto" @click="upload"
              >Upload</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <progrss-loader
      :loading="loading"
      :loaderText="loaderText"
    ></progrss-loader>
  </div>
</template>

<script>
import { reportsRef } from "../../config/firebase";
import uploadFile from "../../mixins/uploadFile";
import ProgrssLoader from "./ProgrssLoader.vue";
export default {
  components: { ProgrssLoader },
  props: ["visible"],
  mixins: [uploadFile],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) this.$emit("close", value);
      },
    },
  },
  data() {
    return {
      form: {
        title: "",
        description: "",
        file: "",
      },
      loading: false,
      loaderText: "Uploading...",
    };
  },
  methods: {
    async upload() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.form.createdAt = new Date();
          this.form.file = await this.uploadFile("reports", this.form.file);
          await reportsRef.add(this.form);
          this.show = false;
          this.form = {
            title: "",
            description: "",
            file: "",
          };
          this.loading = false;
          this.$toast.open({
            message: "Report uploaded successfully",
            type: "success",
            position: "top",
            duration: 5000,
          });
        } catch (error) {
          this.loading = false;
          this.$toast.open({
            message: error.message,
            position: "top",
            type: "error",
          });
        }
      }
    },
  },
};
</script>

<style>
</style>