<template>
  <v-container>
    <h1>Appeal</h1>
    <img src="../assets/images/0001.jpg" alt="" />
    <img src="../assets/images/0002.jpg" alt="" />
    <img src="../assets/images/0003.jpg" alt="" />
  </v-container>
</template>

<script>
export default {
  name: "Appeal",
};
</script>

<style scoped>
h1 {
  color: #4f4f4f;
  text-align: center;
}

img {
  width: 100%;
}
</style>