<template>
  <div class="reports">
    <banner :title="title" class="banner"></banner>
    <all-reports class="all-reports"></all-reports>
  </div>
</template>

<script>
import Banner from "../components/Common/Banner.vue";
import AllReports from "../components/Reports/AllReports.vue";

export default {
  components: { Banner, AllReports },
  data() {
    return {
      title: "Reports",
    };
  },
};
</script>

<style scoped>
.all-reports {
  padding: 2rem 4rem;
}

@media only screen and (max-width: 600px) {
  .all-reports {
    padding: 2rem;
  }

  .banner {
    margin-top: 0;
  }
}
</style>